import React from "react";
import OnBoardingImages from "./onBoardingImageBank";

export const OnBoardingImages1 = () => {
  return (
    <>
      <OnBoardingImages />
    </>
  );
};
