import React from "react";
import styled from "styled-components";

const AppHeader = styled.div`
  background-color: #1a2330;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #cbcbcb;
`;

const TermsP = styled.p`
  margin: 0;
  padding: 2vw 4vw;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  justify-content: center;
  display: flex;
`;

function TermsAndConditions() {
  return (
    <AppHeader>
      <h1>Terms and conditions</h1>
      <h3>Poker is not legal in certain countries and states</h3>
      <h4>Terms of Service</h4>
      <TermsP>
        All services provided by Poker Night Notebook ("PokerNightNotebook.com")
        may be used for lawful purposes only. Transmission, storage or
        presentation of any information, data or material in violation of any
        Global or United States federal, state or city law is prohibited. This
        includes, but is not limited to: copyrighted material, material we judge
        to be threatening or obscene, or material protected by trade secret and
        other statute. The user agrees to indemnify and hold harmless
        PokerNightnotebook.com from any claims resulting from the use of service
        which damages the user or any other party.
      </TermsP>

      <h4>Not a Gambling Site</h4>
      <TermsP>
        While PokerNightNotebook.com allows for users to manage and track their
        own poker games, no poker or gambling of any kind takes place on the app
        or site itself. Users are responsible for following their own local
        state and federal laws that may apply.
      </TermsP>

      <h4>Illegal Activity</h4>
      <TermsP>
        Prohibited are content that promote any illegal activity or present
        content that may be damaging to PokerNightNotebook.com's servers, or any
        other server on the Internet. Links to such materials are also
        prohibited. Examples of unacceptable content or links: posts that
        contain illegal and/or copyrighted information links to pirated software
        links to malicious programs such as spyware, adware, and viruses
      </TermsP>

      <h4>Server Abuse</h4>
      <TermsP>
        Any attempt to undermine or cause harm to a server or user of
        PokerNightNotebook.com is strictly prohibited. As a user you are
        responsible for your account. Should you violate the Terms of Services
        outlined within, your account will be disabled and/or deleted.
      </TermsP>

      <h4>Refusal of Service</h4>
      <TermsP>
        We reserve the right to refuse, cancel or suspend any accounts for any
        reason, at our sole discretion.
      </TermsP>

      <h4>Limitation of Liability</h4>
      <TermsP>
        PokerNightNotebook.com shall not be responsible for any claimed damages,
        including incidental and consequential damages, which may arise from
        PokerNightNotebook.com going off-line or being unavailable for any
        reason whatsoever. Furthermore, PokerNightNotebook.com shall not be
        responsible for any claimed damages, including incidental or
        consequential damages, resulting from the corruption or deletion of any
        content from PokerNightNotebook.com's servers.
      </TermsP>

      <h4>Violations</h4>
      <TermsP>
        Violations of these Acceptable Use Policies should be referred to the
        contact form using the subject "File a Complaint". All complaints will
        be investigated promptly. Failure to follow any term or condition will
        be grounds for immediate account deactivation.
      </TermsP>

      <h4>Account Activation</h4>
      <TermsP>
        By activating your account with PokerNightNotebook.com, you agree to the
        above policies and disclaimer. Upon requesting activation of an account,
        you are required to accept these policies, guidelines and disclaimer.
        PokerNightNotebook.com reserves the right to amend any or all of the
        above policies, guidelines and disclaimer without notification.
      </TermsP>

      <h4>Privacy</h4>
      <TermsP>
        PokerNightNotebook.com collects user information to display on the
        user's profile page as well as for internal use. Certain information,
        such as your name may be displayed on your profile page for public use.
        Other information such as passwords are kept for internal use only and
        will not be shared or sold to any third parties. We use your IP address
        to help diagnose problems with our server and to administer our. service
        use this information for no other reason.
      </TermsP>

      <h4>Pre-Registering users to service / Usage of Contacts</h4>
      <TermsP>
        As part of service users may register, grant access and/or add other
        contacts from their private phone contacts to the service.
        PokerNightNotebook.com shall not be responsible for any claimed damages,
        including incidental and consequential damages, which may arise from
        non-registered users, pre-registered users, and other users that may
        view results or any other information regarding 3rd party users on
        PokerNightNotebook.com . Furthermore, PokerNightNotebook.com shall not
        be responsible for any claimed damages, including incidental or
        consequential damages, resulting from any registration of 3rd party
        users with or without their consent or agreeing to these terms and
        conditions. be advised to confirm these terms with all users registered
        to service.
      </TermsP>

      <h4>Acceptable Use Policy</h4>
      <TermsP>
        When users disseminate information from the Internet, they must keep in
        mind that PokerNightNotebook.com does not review, edit, censor or take
        responsibility for any information its users may create. When users
        place information on the Internet, they have the same liability as other
        authors for copyright infringement, defamation and other harmful speech.
        Also, because the information created is carried over
        PokerNightNotebook.com's network and may reach a large number of people,
        including both users and non-users of PokerNightNotebook.com, users'
        postings to the Internet may affect other users and may affect
        PokerNightNotebook.com's goodwill, business, reputation or operations.
        For these reasons, users violate PokerNightNotebook.com policy and the
        Service Agreement when they engage in the following prohibited
        activities:
      </TermsP>

      <h4>Spamming:</h4>
      <TermsP>
        Sending unsolicited bulk and/or commercial information repeatedly
        through PokerNightNotebook.com's messaging system or repeatedly
        submitting similar posts to the system. It is not only harmful because
        of its negative impact on attitudes toward PokerNight.com, but also
        because it can overload PokerNightNotebook.com's network and disrupt
        service to PokerNightNotebook.com's users. When a complaint is received,
        PokerNightNotebook.com will investigate and shutdown the account that is
        SPAMing. Furthermore PokerNightNotebook.com reserves the right to
        prosecute for this violation.
      </TermsP>

      <h4>Obscene Speech or Materials:</h4>
      <TermsP>
        Using PokerNightNotebook.com to advertise, transmit, store, post,
        display, or otherwise make available child pornography or obscene speech
        or material is prohibited. PokerNightNotebook.com is required by law to
        notify law enforcement agencies when it becomes aware of the presence of
        child pornography on or being transmitted through its network.
      </TermsP>

      <h4>Defamatory or Abusive Language:</h4>
      <TermsP>
        Using PokerNightNotebook.com as a means to transmit or post negative,
        defamatory, harassing, abusive or threatening language.
      </TermsP>

      <h4>Illegal or Unauthorized Access to Other Accounts:</h4>
      <TermsP>
        Accessing, illegally or without authorization, accounts belonging to
        another party, or attempting to penetrate security measures of another
        individual's account (often known as "hacking"). Also, any activity that
        may be used as a precursor to an attempted system penetration (i.e.,
        port scan, stealth scan or other information-gathering activity).
      </TermsP>

      <h4>
        Distribution of Internet Viruses, Worms, Trojan Horses or Other
        Destructive Activities:
      </h4>
      <TermsP>
        Distributing information regarding the creation of and sending Internet
        viruses, worms, Trojan horses, pinging, flooding, mail bombing or denial
        of service attacks. Also, activities that disrupt the use of or
        interfere with the ability of others to effectively use the network or
        any connected network, system, service or equipment.
      </TermsP>

      <h4>Other Activities:</h4>
      <TermsP>
        Engaging in activities, whether lawful or unlawful, that
        PokerNightnotebook.com determines to be harmful to its users,
        operations, reputation, goodwill or customer relations.As we have
        pointed out, the responsibility for avoiding harmful activities just
        described rests primarily with the users. PokerNightNotebook.com will
        not, as an ordinary practice, monitor the communications of its users to
        ensure that they comply with PokerNightNotebook.com's policy or
        applicable law. However, when PokerNightNotebook.com becomes aware of
        harmful activities, it may take any action to stop the harmful activity,
        including, but not limited to, removal of information, shutting down an
        account, implementing screening software designed to block offending
        transmissions, denying access to the site, or any other action deemed
        appropriate by PokerNightNotebook.com.PokerNightNotebook.com will not
        intentionally monitor private messages sent or receive by its users,
        unless required to do so by law, governmental authority or when public
        safety is at stake. PokerNightNotebook.com may, however, monitor its
        service electronically to determine that its facilities are operating
        satisfactorily. Also, PokerNightNotebook.com may disclose information,
        including, but not limited to, information concerning a users, a
        transmission made using our network, or a web site, in order to comply
        with a court order, subpoena, summons, discovery request, warrant,
        statute, regulation or governmental request. PokerNightNotebook.com
        assumes no obligation to inform the user that user information has been
        provided and, in some cases, may be prohibited by law from giving such
        notice. Finally, PokerNightNotebook.com may disclose user information or
        information transmitted over its network where necessary to protect
        PokerNightNotebook.com and others from harm, or where such disclosure is
        necessary to the proper operation of the system. However,
        PokerNightNotebook.com will never sell information to other services or
        outside companies.While PokerNightNotebook.com will make a best effort
        to handle all support issues, users accept the site as-is knowing that
        because the service is an online service, there may be temporary
        downtimes associated with scheduled maintenance, loss of network
        connection, and possibly other unforeseen problems.
      </TermsP>
    </AppHeader>
  );
}

export default TermsAndConditions;
