import React from "react";
import logo from "./assets/pnnLogoSvg.svg";
import appStoreLogo from "./assets/appStoreLogo.svg";
import playStoreLogo from "./assets/playStoreLogo.svg";
import chips from "./assets/chips.png";
import styled from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

const SecondIndexDiv = styled.div`
  margin: 20px;
  background-image: url(${chips});
  height: 70vh;
  margin-top: -70px;
  font-size: 50px;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 550px) {
    height: 35vh;
  }
`;

const FirstIndexDiv = styled.div`
  margin: 20px;
  height: 70vh;

  @media (max-width: 550px) {
    height: 40vh;
  }
`;

const AppHeader = styled.header`
  background-color: #1a2330;
  /* min-height: 100vh; */
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #cbcbcb;
  display: flex;
  flex-direction: row;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const HeadP = styled.div``;

const SecondP = styled.div`
  font-size: 10vh;
  color: #86f9bc;
`;

const ThirdP = styled.p`
  font-size: 14px;
`;
const FourthP = styled.p`
  font-size: 14px;
`;

const LearnButton = styled.button`
  background-color: #86f9bc;
  color: #1a2330;
  font-size: calc(20px + 2vmin);
  flex-basis: 50px;

  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }

  &:hover {
    background-color: #cb8ff0;
    transition-duration: 0.4s;
    border: 1px solid #86f9bc;
  }
`;

const LeftText = styled.div`
  font-size: calc(10px + 5vmin);
  margin: 43px 0px 14px 43px;
`;

const InsideText = styled.div`
  color: #86f9bc;
  margin-right: 50px;
`;

const AppStoreIcon = styled.img`
  height: 15vmin;
  margin-left: 40px;
  display: flex;
`;

const GooglePlayIcon = styled.img`
  height: 15.78vmin;
  pointer-events: none;
  margin-left: 40px;
  margin-top: 10px;
`;

const AppStoreLink = styled.a`
  height: 15vmin;
  margin-left: 40px;
  display: flex;
  width: 325px;
`;
const PlayStoreLink = styled.a`
  height: 15.78vmin;
  margin-left: 40px;
  margin-top: 10px;
  display: flex;
  width: 325px;
`;

function App() {
  return (
    <AppHeader>
      <FirstIndexDiv>
        <HeadP>The Poker Night</HeadP>
        <SecondP>Notebook</SecondP>
        <ThirdP>Are you guys still using pen &amp; Paper?</ThirdP>
        <FourthP>now there is a much better way.</FourthP>
        <LearnButton>
          <Link to="#faqId" smooth>
            Learn More
          </Link>
        </LearnButton>
      </FirstIndexDiv>
      <SecondIndexDiv>
        <LeftText>
          Get it Now!<InsideText>PokerNightNotebook</InsideText>
        </LeftText>
        <AppStoreLink href="https://apps.apple.com/il/app/poker-night-notebook/id1640251996">
          <AppStoreIcon src={appStoreLogo} alt="appStoreLogo" />
        </AppStoreLink>

        <PlayStoreLink href="https://play.google.com/store/apps/details?id=com.pokernightnotebook">
          <GooglePlayIcon src={playStoreLogo} alt="playStoreLogo" />
        </PlayStoreLink>
      </SecondIndexDiv>
    </AppHeader>
  );
}

export default App;
