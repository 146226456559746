import React from "react";
import { Navbar } from "../../navbar";
import TermsAndConditions from "./termsAndConditions";

export const Privacy = () => {
  return (
    <>
      <Navbar />
      <TermsAndConditions />
    </>
  );
};
