import App from "../App";
import { Navbar } from "../navbar";
import About from "../about";
import Contact from "../contact";
import Faq from "../Faq";

export const Main = () => {
  return (
    <>
      <Navbar />
      <App />
      <About />
      <Faq />
      <Contact />
    </>
  );
};
