import styled from "styled-components";
import Faq from "react-faq-component";
/* import React, { useEffect, useState } from "react";
import logo from "./assets/pnnLogoSvg.svg"; */
import negativeBuyin from "../../assets/negativeBuyin.jpg";
import cancelCashout from "../../assets/cancelCashout.png";
import cashOutProccess from "../../assets/cashOutProccess.png";
import buyinProccess from "../../assets/buyinProccess.png";
import buyinFromPlayer from "../../assets/buyinFromPlayer.png";
import midSessionCount from "../../assets/midSessionCount.png";
import timelineExplained from "../../assets/timelineExplained.png";
import personalTimeline from "../../assets/personalTimeline.png";
import newTable from "../../assets/newTable.png";
import switchTable from "../../assets/switchTable.png";
import addNewPlayer from "../../assets/addNewPlayer.png";
import personalStats from "../../assets/personalStats.png";
import userAlreadyExist from "../../assets/userAlreadyExist.png";
import setHost from "../../assets/setHost.png";
import addPlayerTable from "../../assets/addPlayerTable.png";
import WhatsAppButtonGreenLarge from "../../assets/WhatsAppButtonGreenLarge.svg";
const data = {
  title: "FAQ - Need some help?",
  rows: [
    {
      title: "How to Cash-out",
      content: (
        <img
          src={cashOutProccess}
          alt="Cash Out Proccess"
          width={"360px"}
        ></img>
      ),
    },
    {
      title: "Wrong Cash-out, How can I fix it?",
      content: (
        <img src={cancelCashout} alt="Cancel Cash Out" width={"360px"}></img>
      ),
    },
    {
      title: "How to Buy-in?",
      content: (
        <img src={buyinProccess} alt="Buy in Proccess" width={"360px"}></img>
      ),
    },
    {
      title: "How do I change the Buy-in amount?",
      content:
        "You can click on the amount and can insert every number, you can also subtract ",
    },
    {
      title: "Wrong Buy-in, How can I fix it?",
      content: (
        <img src={negativeBuyin} alt="Negative Buy-in" width={"360px"}></img>
      ),
    },
    {
      title: "where did the Buy-in / Cash-out buttons go?",
      content:
        "only one player can buy-in and cash-out, all other players are welcome to view the live session. that means the Buy-in / Cash-out  do not show as you are able to view only.",
    },
    {
      title:
        "The player that opened the session has to leave, how to change Host?",
      content: <img src={setHost} alt="Set Host Page" width={"360px"}></img>,
    },
    {
      title: "Transfer chips between players",
      content: (
        <img
          src={buyinFromPlayer}
          alt="Buy-in from Player"
          width={"360px"}
        ></img>
      ),
    },
    {
      title: "How to fix a session after its over",
      content:
        "In the future we will have a feature for solving this issue, we are working on it. meanwhile please contact us with the group name and data that needs updating.",
    },
    {
      title:
        "I see the session but its on view only mode, I cannot insert data",
      content:
        "only one player can insert buy-in and cash-out, that would be the player who opened the session. All other players are welcome to view the live session. that means the Buy-in / Cash-out do not show as you are able to view only.",
    },
    {
      title: "Verify that the sums add up, use a middle session count up",
      content: (
        <img
          src={midSessionCount}
          alt="Mid Session Count"
          width={"360px"}
        ></img>
      ),
    },
    {
      title: "what is the Timeline?",
      content: (
        <img
          src={timelineExplained}
          alt="Timeline Explained"
          width={"360px"}
        ></img>
      ),
    },
    {
      title: "What is the Personal Timeline",
      content: (
        <img
          src={personalTimeline}
          alt="Personal Timeline"
          width={"360px"}
        ></img>
      ),
    },
    {
      title: "How to switch between different Tables?",
      content: <img src={switchTable} alt="Switch Table" width={"360px"}></img>,
    },
    {
      title:
        "I am playing with another Table, How to open a new Table with them?",
      content: <img src={newTable} alt="New Table" width={"360px"}></img>,
    },
    {
      title: "How to add a new player to the session?",
      content: (
        <img src={addNewPlayer} alt="Add New Player" width={"360px"}></img>
      ),
    },
    {
      title: "How to add a new player to the table?",
      content: (
        <img
          src={addPlayerTable}
          alt="Add New Player Table"
          width={"360px"}
        ></img>
      ),
    },
    {
      title:
        "Why do I have to imput the same inital Buy-in amount for all the players?",
      content:
        "Most games have a fixed number of chips that they use at the start of a session, you can add/subtract the Buy-ins during the session.",
    },
    {
      title: "What are Personal statistics and how can I view them",
      content: (
        <img
          src={personalStats}
          alt="Personal Statistics"
          width={"360px"}
        ></img>
      ),
    },
    {
      title: "Are there any Table statistics?",
      content: "Not yet, but we are working hard on it",
    },
    {
      title: "User already registered",
      content: (
        <img
          src={userAlreadyExist}
          alt="User Already Exists"
          width={"360px"}
        ></img>
      ),
    },
    {
      title: "Didn't find an Answer?",
      content: "Simply contact us, click on the link below",
    },
  ],
};

const styles = {
  bgColor: "#1a2330",
  titleTextColor: "#86f9bc",
  rowTitleColor: "#cb8ff0",
  rowContentColor: "#cbcbcb",
  arrowColor: "#86f9bc",
};

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true,
};

const ContainerDiv = styled.div`
  padding-left: 15px;
  box-shadow: inset 0 0 0 15px #1a2330;
`;

const AppHeader = styled.div`
  background-color: #1a2330;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #cbcbcb;
`;

export default function AppFaq1() {
  return (
    <>
      <ContainerDiv id="faqId">
        <Faq data={data} styles={styles} config={config} />
      </ContainerDiv>
      <AppHeader>
        <a href="https://wa.me/972525683884">
          <img
            src={WhatsAppButtonGreenLarge}
            alt="WhatsAppButtonGreenLarge"
            width={"360px"}
          ></img>
        </a>
      </AppHeader>
    </>
  );
}
