import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Main } from "./pages/main";
import { Privacy } from "./pages/privacy";
import { AppFaq1 } from "./pages/appFaq";
import { OnBoardingImages1 } from "./pages/onBoardingImageBank";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="appfaq" element={<AppFaq1 />} />
        <Route path="onboardingimagebank" element={<OnBoardingImages1 />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
