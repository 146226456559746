import React from "react";
import styled from "styled-components";

const AppHeader = styled.div`
  background-color: #1a2330;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #cbcbcb;
`;

const ContactHeaderDiv = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  justify-content: center;
  display: flex;
`;

const ContactHeader1 = styled.div`
  color: #ffffff;
  order: 1;
  justify-content: center;
  display: inline-block;
`;

const ContactHeader2 = styled.div`
  color: #86f9bc;
  order: 2;
  justify-content: center;
  display: inline-block;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameComponent = styled.input`
  background-color: #11161f;
  color: white;
  border-radius: 4px;
  border: 1px solid white;
  padding: 8px;
  margin: 15px 15px 15px;
`;
const EmailComponent = styled.input`
  background-color: #11161f;
  color: white;
  border-radius: 4px;
  border: 1px solid white;
  padding: 8px;
  margin: 15px 15px 15px;
`;
const MessageComponent = styled.textarea`
  background-color: #11161f;
  color: white;
  border-radius: 4px;
  border: 1px solid white;
  padding: 8px;
  margin: 15px 15px 15px;
  flex-basis: 150px;
`;

const ButtonComponent = styled.button`
  background-color: #86f9bc;
  color: #1a2330;
  border-radius: 15px;
  font-size: calc(8px + 2vmin);
  flex-basis: 50px;

  &:hover {
    background-color: #cb8ff0;
    transition-duration: 0.4s;
    border: 1px solid #86f9bc;
  }
`;

const Alink = styled.a`
  text-decoration: none;
  color: black;
`;

function Contact() {
  return (
    <AppHeader id="contactId">
      <ContactHeaderDiv>
        <h3>
          <ContactHeader1>Need some Help?&nbsp;&nbsp;</ContactHeader1>
          <ContactHeader2> Contact us Here!</ContactHeader2>
        </h3>
      </ContactHeaderDiv>
      <FormContainer>
        <NameComponent type={"text"} placeholder={"Name.."}></NameComponent>
        <EmailComponent type={"text"} placeholder={"Email.."}></EmailComponent>
        <MessageComponent placeholder={"Your Message.."}></MessageComponent>
        <ButtonComponent>
          <Alink href="mailto:nightpokercs101@gmail.com?subject='Inquery from website'&body='Please add to this email the phone number you used for registration'">
            Send Message
          </Alink>
        </ButtonComponent>
      </FormContainer>
    </AppHeader>
  );
}

export default Contact;
