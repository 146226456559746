import React from "react";
import styled from "styled-components";
import appImage from "./assets/appImage.svg";

const Container = styled.div`
  align-items: center;
  background-color: #364963;
  width: 100%;
  font-size: calc(10px + 2vmin);
  color: #cbcbcb;
  min-height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: row;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const ParagraphContainer = styled.p`
  flex-wrap: wrap;
  padding: 20px;
`;

const TextContainer = styled.p`
  font-size: 3.2vmin;
`;

const H5Container = styled.h4`
  color: #86f9bc;
`;

const ImageContainer = styled.img`
  width: 50vw;
  flex-wrap: wrap;
`;

function About() {
  return (
    <Container id="aboutId">
      <ParagraphContainer>
        <h1 id="about">Poker Night Notebook</h1>
        <H5Container>Why</H5Container>
        <TextContainer>
          We believe there is a better way to Manage, Track and Enjoy your Poker
          Night.
        </TextContainer>
        <H5Container>How</H5Container>
        <TextContainer>
          Create an online platform for recreational poker players to Manage &
          Track their Poker Night.
        </TextContainer>
        <H5Container>What</H5Container>
        <TextContainer>
          In an intuitive and easy way using your phone to insert just the
          Buy-in’s and Cash-out’s we will do the rest and let you sit back and
          enjoy the game
        </TextContainer>
      </ParagraphContainer>
      <ImageContainer src={appImage} alt="App-Image" />
    </Container>
  );
}

export default About;
