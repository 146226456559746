import textTutorial from "../../assets/textTutorial.jpeg";
import textIcon from "../../assets/textIcon.jpeg";
import textIcon1 from "../../assets/textIcon1.png";
import textIcon2 from "../../assets/textIcon2.png";
import textIcon3 from "../../assets/textIcon3.png";
import textIconA from "../../assets/textIconA.png";
import textIconB from "../../assets/textIconB.png";
import textIconC from "../../assets/textIconC.png";
import textIconD from "../../assets/textIconD.png";
import page_1 from "../../assets/page_1.jpg";
import page_2 from "../../assets/page_2.png";
import page_3 from "../../assets/page_3.jpg";
import page_4 from "../../assets/page_4.png";
import page_5 from "../../assets/page_5.png";
import introVid from "../../assets/introVid.mp4";
import PokerNightNotebookCaptionsSlim from "../../assets/PokerNightNotebookCaptionsSlim.mp4";


export default function OnBoardingImages() {
  return (
    <>
      <img src={textTutorial} alt="textTutorial" width={"360px"}></img>
      <img src={textIcon} alt="textIcon" width={"360px"}></img>
      <img src={textIcon1} alt="textIcon1" width={"360px"}></img>
      <img src={textIcon2} alt="textIcon2" width={"360px"}></img>
      <img src={textIcon3} alt="textIcon3" width={"360px"}></img>
      <img src={textIconA} alt="textIconA" width={"360px"}></img>
      <img src={textIconB} alt="textIconB" width={"360px"}></img>
      <img src={textIconC} alt="textIconC" width={"360px"}></img>
      <img src={textIconD} alt="textIconD" width={"360px"}></img>
      <img src={page_1} alt="page_1"></img>
      <img src={page_2} alt="page_2"></img>
      <img src={page_3} alt="page_3"></img>
      <img src={page_4} alt="page_4"></img>
      <img src={page_5} alt="page_5"></img>
      <video preload="ture" controls autoPlay src={introVid}></video>
      <video preload="ture" controls autoPlay src={PokerNightNotebookCaptionsSlim}></video>
    </>
  );
}
