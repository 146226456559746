import React from "react";
import styled from "styled-components";
import logo from "./assets/logo.png";
import { HashLink as Link } from "react-router-hash-link";

var isMobile: string;
isMobile = "none";

function myFunction() {
  if (isMobile === "none" || isMobile === undefined) {
    isMobile = "block";
  } else if (isMobile === "block") {
    isMobile = "none";
  }

  console.log(isMobile);
}

const Container = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  align-items: center;
  background-color: #1a2330;
  width: 100%;
  font-size: calc(10px + 2vmin);
  color: #cbcbcb;

  display: flex;
  flex-direction: row;
`;

const HeaderLogo = styled.div`
  flex: 2;
`;

const Margin = styled.div`
  flex: 6;
`;

const NavItem = styled.div`
  flex: 2;
  margin: 3px 3px 3px 3px;

  @media (max-width: 550px) {
    display: ${isMobile};
  }
`;

const A = styled.a`
  color: #cbcbcb;
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    background-color: #86f9bc;
    color: #1a2330;
    border-radius: 4px;
  }

  a:link {
    text-decoration: none;
    color: #cbcbcb;
  }

  a:visited {
    text-decoration: none;
    color: #cbcbcb;
  }

  a:hover {
    text-decoration: none;
    color: #1a2330;
    border-radius: 4px;
  }

  a:active {
    text-decoration: none;
    color: #cbcbcb;
  }
`;

const ButtonCss = styled.button`
  background-color: #86f9bc;
  color: #1a2330;
  border-radius: 4px;
  font-size: calc(10px + 2vmin);
  margin: 3px 3px 3px 3px;

  &:hover {
    background-color: #1a2330;
    color: #86f9bc;
  }

  @media (max-width: 550px) {
    display: none;
  }

  a:link {
    text-decoration: none;
    color: #1a2330;
  }

  a:visited {
    text-decoration: none;
    color: #1a2330;
  }

  a:hover {
    text-decoration: none;
    color: #86f9bc;
    border-radius: 4px;
  }

  a:active {
    text-decoration: none;
    color: #1a2330;
  }
`;

const Hamburger = styled.div`
  flex: 2;
  display: none;
  font-size: calc(10px + 5vmin);

  @media (max-width: 550px) {
    display: block;
  }
`;

const AppLogoNavbar = styled.img`
  height: calc(10px + 25);
  pointer-events: none;
`;

export const Navbar = () => {
  return (
    <Container>
      <HeaderLogo>
        <AppLogoNavbar src={logo} alt="logo" />
      </HeaderLogo>
      <Margin></Margin>
      <NavItem id="navItemName">
        <A href="#">Home</A>
      </NavItem>
      <NavItem>
        <A>
          <Link to="#aboutId" smooth>
            About
          </Link>
        </A>
      </NavItem>
      <NavItem>
        <A>
          <Link to="#contactId" smooth>
            Contact
          </Link>
        </A>
      </NavItem>
      <NavItem>
        <ButtonCss>
          <Link to="#faqId" smooth>
            FAQ
          </Link>
        </ButtonCss>
      </NavItem>
      <Hamburger onClick={myFunction}>&nbsp;&nbsp;&nbsp; &#9776;</Hamburger>
    </Container>
  );
};
